<template>
  <div class="overview-stats">
    <v-row
      no-gutters
      class="white box-shadow-sm border-radius-1em overflow-hidden"
    >
      <v-col v-if="overviewData.followers" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.followers" class="overview-card--teal" text="Followers" icon="groups" />
      </v-col>

      <v-col v-if="overviewData.views" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.views" class="overview-card--orange" text="Average Views" icon="visibility" />
      </v-col>

      <v-col v-if="overviewData.likes" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.likes" class="overview-card--green" text="Average Likes" icon="thumbs_up_down" />
      </v-col>

      <v-col v-if="overviewData.dislikes" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.dislikes" class="overview-card--brown" text="Average Dislikes" icon="thumbs_up_down" />
      </v-col>

      <v-col v-if="overviewData.comments" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.comments" class="overview-card--brown" text="Average Comments" icon="forum" />
      </v-col>

      <v-col v-if="overviewData.engagementRate" :cols="12" :sm="6" :lg="3">
        <stat-box :title="overviewData.engagementRate + '%'" :class="engagementRateClass" text="Engagement Rate" icon="leaderboard" />
      </v-col>

      <v-col v-if="overviewData.reelsViews" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.reelsViews" class="overview-card--orange" text="Average Reel Views" icon="movie" />
      </v-col>

      <v-col v-if="overviewData.reelsLikes" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.reelsLikes" class="overview-card--green" text="Average Reel Likes" icon="thumbs_up_down" />
      </v-col>

      <v-col v-if="overviewData.reelsComments" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.reelsComments" class="overview-card--brown" text="Average Reels Comments" icon="forum" />
      </v-col>

      <!-- <v-col
        v-if="overviewData.reelsEngagementRate"
        :cols="12"
        :sm="6"
        :lg="3"
      >
        <stat-box
          :title="overviewData.reelsEngagementRate + '%'"
          class="overview-card--green"
          text="Reels Engagement Rate"
          icon="leaderboard"
        />
      </v-col> -->

      <v-col v-if="overviewData.paidPostPerformance" :cols="12" :sm="6" :lg="3">
        <stat-box
          :title="overviewData.paidPostPerformance + '%'"
          class="overview-card--orange"
          text="Branded Posts ER"
          tooltip-text="Shows how disclosed sponsored posts perform compared to organic posts in terms of the average number of engagements they receive. For example, 10% means that you can overpay by 10 times for a post if you base your pricing on average engagements as, for example, instead of usual average 10,000 engagements you might get just 1,000 on your sponsored post. Please note that we take into account disclosure by Paid Partnerships feature and by 38 sponsored posts’ hashtags including #ad, #sponsored, #paid as well as others."
        />
      </v-col>

      <v-col v-if="overviewData.postsCount" :cols="12" :sm="6" lg="3">
        <stat-box :title="overviewData.postsCount" class="overview-card--brown" text="Posts Count" icon="grid_view" />
      </v-col>

      <v-col v-if="overviewData.postsWithHiddenLikes" :cols="12" :sm="6" :lg="3">
        <stat-box :title="overviewData.postsWithHiddenLikes + '%'" class="overview-card--red" text="Posts w Hidden Likes" icon="hide_source" />
      </v-col>

      <v-col v-if="overviewData.location" :cols="12" :sm="6" :lg="3">
        <stat-box class="overview-card--green" text="Location" icon="place">
          <template v-slot:title>
            <div class="d-flex align-center justify-center">
              <img
                v-if="overviewData.locationFlag"
                :src="proxyUrl(`https://flagcdn.com/w20/${overviewData.locationFlag.toLowerCase()}.png`, true)"
                height="18px"
              />
              &nbsp;
              {{ overviewData.location }}
            </div>
          </template>
        </stat-box>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import the child component
const StatBox = () => import(/* webpackChunkName: "blocks-stat-box" */ "@/blocks/user-profile/common/StatBox.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Stats",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    },

    overviewData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    StatBox
  },

  // Define computable readonly properties
  computed: {
    engagementRateClass() {
      if (!this.overviewData.engagementRate) {
        return null
      } else if (this.overviewData.engagementRate <= 1) {
        return "overview-card--bg-red"
      } else if (this.overviewData.engagementRate > 2) {
        return "overview-card--bg-green"
      } else {
        return "overview-card--bg-orange"
      }
    }
  }
}
</script>

<style lang="stylus">
.overview-stats
  .overview-card
    &--red
      color rgb(244, 67, 54) !important

    &--teal
      color rgb(0, 150, 136) !important

    &--orange
      color rgb(245, 127, 23) !important

    &--green
      color rgb(56, 142, 60) !important

    &--brown
      color rgb(130, 119, 23) !important

    &--bg-red
      color #fff !important
      background linear-gradient(rgb(229, 57, 53), rgb(239, 83, 80)) !important

    &--bg-green
      color #000 !important
      background linear-gradient(rgb(129, 199, 132), rgb(76, 175, 80)) !important

    &--bg-orange
      color #fff !important
      background linear-gradient(rgb(239, 108, 0), rgb(245, 124, 0)) !important

  .col-12
    border-right 1px solid #eee
    border-bottom 1px solid #eee

    &:nth-child(4n)
      border-right 0

    &:nth-last-child(-n+4):nth-child(-n+4)
      border-bottom 0
</style>
